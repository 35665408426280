<template>
  <div class="mod-menu">
    <el-form :inline="true" :model="searchForm">
      <el-form-item>
        <el-button
          v-if="isAuth('sys:menu:save')"
          type="primary"
          @click="addOrUpdateHandle()"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border style="width: 100%">
      <el-table-column
        prop="menuId"
        header-align="center"
        align="left"
        width="80"
        label="ID"
      />
      <table-tree-column
        prop="name"
        header-align="center"
        tree-key="menuId"
        width="150"
        label="名称"
      />
      <el-table-column
        prop="parentName"
        header-align="center"
        align="center"
        width="120"
        label="上级菜单"
      />
      <el-table-column header-align="center" align="center" label="图标">
        <template slot-scope="scope">
          <icon-svg :name="scope.row.icon || ''" />
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="类型"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 0" size="small"> 目录 </el-tag>
          <el-tag v-else-if="scope.row.type === 1" size="small" type="success">
            菜单
          </el-tag>
          <el-tag v-else-if="scope.row.type === 2" size="small" type="info">
            按钮
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderNum"
        header-align="center"
        align="center"
        label="排序号"
      />
      <el-table-column
        prop="url"
        header-align="center"
        align="center"
        width="150"
        show-tooltip-when-overflow
        label="菜单URL"
      />
      <el-table-column
        prop="perms"
        header-align="center"
        align="center"
        width="150"
        show-tooltip-when-overflow
        label="授权标识"
      />
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('sys:menu:update')"
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.menuId)"
          >
            修改
          </el-button>
          <el-button
            v-if="isAuth('sys:menu:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.menuId)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    />
  </div>
</template>

<script>
import TableTreeColumn from '@/components/table-tree-column';
import AddOrUpdate from './menu-add-or-update';

export default {
  components: {
    TableTreeColumn,
    AddOrUpdate,
  },
  data() {
    return {
      searchForm: {},
      dataList: [],
      addOrUpdateVisible: false,
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/sys/menu/list',
        method: 'get',
      }).then(({ data }) => {
        this.dataList = this.treeDataTranslate(
          data.menuList,
          'menuId',
          'parentId',
          'childrens',
        );
      });
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定对[id=${id}]进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: `/sys/menu/delete/${id}`,
            method: 'post',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.el-popper::v-deep {
  max-height: 500px;
  max-width: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
